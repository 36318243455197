/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { MessagePayload, onMessage } from 'firebase/messaging'
import { useBrowserStorage } from 'vspry-hooks'
import { ConfirmSwal, FlexBox } from 'vspry-style-components'

// import { restQuery } from 'api/interface'
import { errorlessMessaging, getMessagingToken } from 'api/firebase'
import { useAuth } from 'context/authContext'
import { useMessaging } from 'context/messageContext'
import theme from 'styles/theme'
import { useAppConfig } from 'context/appConfigContext'
import { addUserDevice } from 'api/common'
import { FirebaseError } from 'firebase/app'

import Swal from 'sweetalert2'
import NotificationTile from './Notification'

const NotificationContainer = styled(FlexBox)`
    position: fixed;
    z-index: 100;
`

// initiates notifications for a user if browser supported
export const initNotifications = async (setDevice: (d: string) => void) => {
    console.debug('Initializing notifications...')
    try {
        const notificationToken = await getMessagingToken()
        if (!notificationToken) return
        console.debug('Adding user device...')
        const r = await addUserDevice(notificationToken)
        if (r?.success && 'deviceID' in r && r.deviceID) setDevice(r.deviceID ?? '')
    } catch (e) {
        console.debug(e)
        if (!(e instanceof FirebaseError) || (!e.message.includes('permission-blocked') && !e.message.includes('unsupported-browser'))) throw e
    }
}

export default function NotificationHandler() {
    const [notifications, setNotifications] = useState([] as MessagePayload[])
    const [displayNotifications, setDisplayNotifications] = useState([] as MessagePayload[])
    const { refresh } = useMessaging()
    const { appConfig } = useAppConfig()
    const { user } = useAuth()
    const [notifPreference, setNotifPreference] = useBrowserStorage('notification_preference', true)
    const [notifDevice, setNotifDevice] = useBrowserStorage('notification_device', '')

    const nRef = useRef(notifications)
    nRef.current = notifications

    useEffect(() => {
        const init = async () => {
            if (!user || typeof Notification === 'undefined' || !Notification || !appConfig?.device_notifications_enabled) return null
            if (notifPreference && Notification.permission === 'granted' && !notifDevice) {
                initNotifications(setNotifDevice)
                window.addEventListener('focus', refresh)
                return () => window.removeEventListener('focus', refresh)
            }
            if ((Notification.permission === 'default' || Notification.permission === 'denied') && notifPreference) {
                console.debug('Asking permission to enable notifications...')
                const enable = await ConfirmSwal.fire({
                    title: 'Enable notifications?',
                    text: 'We use notifications to send you important documents and messages.',
                    iconColor: theme.text,
                    confirmButtonColor: theme.primaryOBJ,
                })
                if (enable?.value) {
                    const res = await Notification.requestPermission()
                    console.debug(`Notification permission: ${res}`)
                    if (res === 'granted') {
                        initNotifications(setNotifDevice)
                        window.addEventListener('focus', refresh)
                        return () => window.removeEventListener('focus', refresh)
                    }
                } else {
                    console.debug('Notification permission denied')
                    setNotifPreference(false)
                }
            }
            return null
        }

        const timer = setTimeout(async () => {
            const isPopupActive = Swal.isVisible()

            if (!isPopupActive) {
                await init()
            } else {
                console.debug('Popup already active, delaying notification prompt')
            }
        }, 5000)

        return () => clearTimeout(timer)
    }, [user])

    useEffect(() => {
        const init = async () => {
            const m = await errorlessMessaging()
            if (m) {
                return onMessage(m, (payload) => {
                    refresh()
                    if (appConfig.device_notifications_enabled) setNotifications((n) => [...n, payload])
                })
            }
            return null
        }

        init()
    }, [user, refresh])

    useEffect(() => {
        setDisplayNotifications([])
        if (notifications.length === 0) return
        for (let i = 0; i < (notifications.length > 10 ? 10 : notifications.length); i++) {
            setDisplayNotifications((n) => [notifications[i], ...n])
        }
    }, [notifications])

    const onClear = (n: MessagePayload) => {
        if (nRef.current) setNotifications(nRef.current.filter((i) => i.data?.['id'] !== n.data?.['id']))
    }

    if (notifications.length > 0)
        return (
            <NotificationContainer direction='column' align='center' width='100%' gap='xSmall' margin='small'>
                {displayNotifications.map((n) => (
                    <NotificationTile key={n.data?.['id']} {...n.notification} onClear={() => onClear(n)} />
                ))}
            </NotificationContainer>
        )
    return null
}
