/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { FlexBox, Text } from 'vspry-style-components'

import { useAuth } from 'context/authContext'
import { useLocale } from 'context/localeContext'

import PageContainer from 'components/style-elements/containers/PageContainer'
import VerifyEmail from 'components/verification/VerifyEmail'
import VerifyPhone from 'components/verification/VerifyPhone'
import { useFunctionState } from 'vspry-hooks'
import { useEffect } from 'react'
import FetchingPage from 'views/public/FetchingPage'
import { verifyUserEmail } from 'api/verification'
import { useNavigate } from 'react-router-dom'
import { isHolding } from 'api/common'

export default function VerificationPage() {
    const navigate = useNavigate()
    const { translate } = useLocale()
    const { identity, user, currentProduct, getUser } = useAuth()
    const [{ loading }, setState] = useFunctionState({ loading: true })

    useEffect(() => {
        const mainAddress = identity?.verifiableAddresses?.[0]
        if (!mainAddress?.verified) {
            setState({ loading: false })
            return
        }

        const verifyEmail = async () => {
            const res = await verifyUserEmail({ email: mainAddress.value })
            if (!res) {
                setState({ loading: false })
                return
            }
            getUser()
            navigate('/')
        }
        verifyEmail()
    }, [identity])

    if (loading) return <FetchingPage />

    return (
        <PageContainer
            title={isHolding(currentProduct) ? currentProduct?.product ?? translate('navigation.productHome') : translate('navigation.productHome')}
        >
            <FlexBox $column margin='large' $grow justify='center' $align gap='medium'>
                <FlexBox $column>
                    <Text $bold margin='no'>
                        {translate('pages.verification.title')}
                    </Text>
                    <Text size='small' margin='no'>
                        {translate('pages.verification.instructions')}
                    </Text>
                </FlexBox>
                {!user?.emailVerified && <VerifyEmail />}
                {user?.emailVerified && !user.phoneVerified && user.phone && <VerifyPhone />}
            </FlexBox>
        </PageContainer>
    )
}
